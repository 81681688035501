import { ActionCreator, Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DashboardPlayVideoActionTypes } from './types';
import { ApplicationState } from '../index';

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const startVideoRequest: AppThunk = (videoData) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: DashboardPlayVideoActionTypes.PLAY_VIDEO_REQUEST,
            payload: videoData,
        });
    };
};

