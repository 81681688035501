import { Suspense, Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router"
import api, {
  ENDPOINT,
  decryptAPIRes,
  socket,
  GITHUB_ASSETS_HOST_URL,
} from "../../helpers/api"
import Loader from "../../components/common/SiteLoader/Loader"
import Header from "../../components/layout/header/header"
import { routeAnimationJson, dynamicARoute } from "../../routeWiseAnimation"
import ResHeader from "../../components/layout/Resposive/ResHeader"
import $ from "jquery"
import DashboardVideoPlayer from "../../components/dashboard/DashboardVideoPlayer"
import Tour from "reactour"
import axios, { AxiosRequestConfig } from "axios"
import { setChatDetailEmpty } from "../../store/chat/action"
import useWindowSize from "../../components/dashboard/useWindowSize"
import React from "react"

const Layout = ({ children }) => {
  const history = useHistory()
  const { width, height }: any = useWindowSize()
  const [isTourOpen, setIsTourOpen]: any = useState(
    localStorage.getItem("websiteTour")
  )
  useEffect(() => {
    const token: any = localStorage.getItem("token")
    const role: any = localStorage.getItem("role")
    if (localStorage.token && localStorage.role) {
      try {
        const config: AxiosRequestConfig = {
          method: "get",
          url: ENDPOINT + "/auth/check",
          headers: {
            "x-authorization": `${token}`,
          },
        }
        axios(config)
          .then(function (response) {
            var payload = decryptAPIRes(response.data.data)
            if (payload.code === 200) {
            } else {
              if (role == "ELearner") {
                if (history.location.pathname.includes("/explore-classes")) {
                } else {
                  // history.push('/parent/expired-package');
                }
                // history.push('/expired-package');
              } else if (role == "Parent") {
                if (
                  history.location.pathname.includes(
                    "/parent/explore-classes"
                  ) ||
                  history.location.pathname.includes("/parent/plan-detail/")
                ) {
                } else {
                  history.push("/parent/expired-package")
                }
                // history.push('/parent/expired-package');
              } else {
                history.push("/educator/expired-package")
              }
            }
          })
          .catch(function (error) {
            var data = decryptAPIRes(error)
            console.log("error", data)
          })
      } catch (err) {
        console.log("err", err)
      }
    }

    $(document).ready(function () {
      setTimeout(function () {
        var div_height = $(".inner_body_container").outerHeight()
        var screen_height = $(window).height() - 80

        if (screen_height > div_height) {
          $(".bottom_frame_img").css({ position: "absolute", "z-index": "-1" })
        } else {
          $(".bottom_frame_img").css({ position: "relative" })
        }
      }, 70)
    })
  }, [])

  useEffect(() => {
    console.log(height - 66, "===height")
  }, [height])

  useEffect(() => {
    if (history?.location?.pathname != "/chat") {
      setChatDetailEmpty()
      socket.emit("closechatpanel", localStorage.getItem("token"))
    }
  }, [history])

  useEffect(() => {
    setIsTourOpen(localStorage.getItem("websiteTour"))
  }, [localStorage.getItem("websiteTour")])

  const closeTour = async () => {
    try {
      if (isTourOpen) {
        const payload = await api.get("/child/websitetour")
        if (payload.status === 200) {
          localStorage.setItem("websiteTour", "false")
        }
        setIsTourOpen(false)
      }
    } catch (err) {
      console.log("error", err)
    }
  }

  const tourConfig = [
    {
      selector: '[data-tut="reactour__letsstart"]',
      content: "Site tour, let's start",
    },
    {
      selector: '[data-tut="reactour__myprofile"]',
      content:
        "Manage your Avatar, Meekoins, Profile and see your amazing Meeko Journey here",
    },
    {
      selector: '[data-tut="reactour__calendar"]',
      content: "See your class schedule here",
    },
    {
      selector: '[data-tut="reactour__exploreclass"]',
      content: "Explore new classes to upgrade your skills",
    },
    {
      selector: '[data-tut="reactour__joinliveclass"]',
      content: "Join your classes directly from here",
    },
    {
      selector: '[data-tut="reactour__chatbox"]',
      content: "Chat with your educator here",
    },
    {
      selector: '[data-tut="reactour__notebook"]',
      content: "See all your materials of classes here",
    },
    {
      selector: '[data-tut="reactour__helpdesk"]',
      content: "If you have any queries or questions go to Help Desk",
    },
    {
      selector: '[data-tut="reactour__avatar"]',
      content:
        "See your Avatar here and keep giving them a fresh look from ‘My Profile’",
    },
    {
      selector: '[data-tut="reactour__spaceship"]',
      content: "Build your spaceship and collect parts by completing classes",
    },
    {
      selector: '[data-tut="reactour__flexem"]',
      content:
        "Reach new planets and get Flexems and flex them to your friends",
    },
    {
      selector: '[data-tut="reactour__nextclass"]',
      content: "See your upcoming classes here",
    },
  ]

  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        {!children.props.liveClasslayout ? (
          <>
            {width > 1025 && (
              <Tour
                onRequestClose={closeTour}
                steps={tourConfig}
                isOpen={isTourOpen == "true" ? true : false}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor="#5cb7b7"
                disableDotsNavigation={true}
                disableInteraction={true}
                lastStepNextButton={
                  <button className="completeTour" onClick={() => closeTour()}>
                    Done! Let's start learning
                  </button>
                }
              />
            )}
            <div
              className="wrap_cantainer"
              data-tut="reactour__letsstart"
              style={{
                height: height + "px",
              }}
            >
              <DashboardVideoPlayer />
              <div
                className="wrap_inner_cantainer"
                style={{
                  height: "inherit",
                }}
              >
                <section className="frame_header_section">
                  <div className="frame_header_row">
                    <div className="frame_header_column">
                      <div className="frame_header_block">
                        <img
                          src={
                            GITHUB_ASSETS_HOST_URL + `img/frame/Top_Frame.png`
                          }
                          alt="Frame_Top"
                          className="top_frame_img"
                        />
                      </div>
                    </div>
                  </div>
                </section>
                        {console.log(history.location.pathname, '=====history.location.pathname')}
                <section
                  className="main_body_section"
                  style={{
                    height: `${
                      width < 768
                        ? history.location.pathname.includes("plan-detail") ||
                          history.location.pathname.includes("topup-detail") ||
                          history.location.pathname.includes("chat") ||
                          history.location.pathname.includes("notebook") ||
                          history.location.pathname.includes("save-class") ||
                          history.location.pathname.includes("notification") ||
                          history.location.pathname.includes("refer") ||
                          history.location.pathname.includes(
                            "learner/help-desk"
                          ) ||
                          history.location.pathname.includes(
                            "learner/tickets"
                          ) ||
                          history.location.pathname.includes(
                            "explore-classes"
                          ) ||
                          history.location.pathname.includes(
                            "learner/course"
                          ) ||
                          history.location.pathname.includes(
                            "learner/classes"
                          ) ||
                          history.location.pathname.includes(
                            "educator/profile"
                          ) ||
                          history.location.pathname.includes(
                            "learner/policies"
                          ) ||
                          history.location.pathname.includes(
                            "learner/about-us"
                          ) ||
                          history.location.pathname.includes("learner/blog") ||
                          history.location.pathname.includes(
                            "profile/meekoin-package"
                          ) ||
                          history.location.pathname.includes(
                            "profile/meekoin-detail"
                          ) ||
                          history.location.pathname.includes(
                            "/learner/contact-us/"
                          ) ||
                          history.location.pathname.includes("thank-you") ||
                          history.location.pathname.includes("dashboard")
                          ? height
                          : height - 66
                        : "auto"
                    }px`,
                    overflow: `${width < 768 ? "" : ""}`,
                  }}
                >
                  {console.log(width, '============width')}
                  {width > 1025 ? (
                    <Header userType="" />
                  ) : (
                    <ResHeader userType="" />
                  )}

                  <div
                    className="inner_body_container"
                    style={{
                      height: `${
                        width < 768
                          ? history.location.pathname.includes("plan-detail") ||
                            history.location.pathname.includes(
                              "topup-detail"
                            ) ||
                            history.location.pathname.includes("chat") ||
                            history.location.pathname.includes("notebook") ||
                            history.location.pathname.includes("save-class") ||
                            history.location.pathname.includes(
                              "notification"
                            ) ||
                            history.location.pathname.includes("refer") ||
                            history.location.pathname.includes(
                              "learner/help-desk"
                            ) ||
                            history.location.pathname.includes(
                              "learner/tickets"
                            ) ||
                            history.location.pathname.includes(
                              "explore-classes"
                            ) ||
                            history.location.pathname.includes(
                              "learner/course"
                            ) ||
                            history.location.pathname.includes(
                              "learner/classes"
                            ) ||
                            history.location.pathname.includes(
                              "educator/profile"
                            ) ||
                            history.location.pathname.includes(
                              "learner/policies"
                            ) ||
                            history.location.pathname.includes(
                              "learner/about-us"
                            ) ||
                            history.location.pathname.includes(
                              "learner/blog"
                            ) ||
                            history.location.pathname.includes(
                              "profile/meekoin-package"
                            ) ||
                            history.location.pathname.includes(
                              "profile/meekoin-detail"
                            ) ||
                            history.location.pathname.includes(
                              "/learner/contact-us/"
                            ) ||
                            history.location.pathname.includes("thank-you")
                            ? height
                            : height - 156
                          : "auto"
                      }px`,
                      overflow: `${width < 768 ? "hidden auto" : ""}`,
                      // overflowX: "hidden",
                    }}
                  >
                    <div
                      className={
                        routeAnimationJson[history.location.pathname]
                          ? `${
                              routeAnimationJson[history.location.pathname]
                                .animation
                            }`
                          : ""
                      }
                    >
                      {children}
                    </div>
                  </div>

                  {/* <div className="footer_content">
<p>2022 © MEEKO Enterprises Private Limited.</p>
<p>All Rights Reserved. Above mentioned contents are subject to change without notice and are copyrighted by Meeko Enterprises Private Limited.</p>
                  </div> */}
                </section>

                <section className="footer_frame_section">
                  <div className="footer_frame_row">
                    <div className="footer_frame_column">
                      <div
                        className={`footer_frame_block ${
                          history.location.pathname.includes(
                            "/explore-classes/card"
                          ) ||
                          history.location.pathname.includes(
                            "/explore-classes"
                          ) ||
                          history.location.pathname.includes(
                            "/learner/help-desk"
                          ) ||
                          history.location.pathname.includes("/attend-test") ||
                          history.location.pathname.includes(
                            "/preview-test-result"
                          ) ||
                          history.location.pathname.includes(
                            "/compare-classes"
                          ) ||
                          history.location.pathname.includes("/thank-you") ||
                          history.location.pathname.includes("/failure") ||
                          history.location.pathname.includes(
                            "/learner/policies"
                          ) ||
                          history.location.pathname.includes(
                            "learner/about-us"
                          ) ||
                          history.location.pathname.includes(
                            "/learner/tickets"
                          ) ||
                          history.location.pathname.includes("/ticket/") ||
                          history.location.pathname.includes(
                            "/profile/meekoin-package"
                          ) ||
                          history.location.pathname.includes(
                            "/profile/meekoin-detail"
                          ) ||
                          history.location.pathname.includes(
                            "/profile/meekoins/success"
                          ) ||
                          history.location.pathname.includes(
                            "/profile/meekoins/failure"
                          ) ||
                          history.location.pathname.includes(
                            "/explore-pre-recorded-classes"
                          ) ||
                          history.location.pathname.includes("/topup-detail") ||
                          history.location.pathname.includes("refer") ||
                          history.location.pathname.includes("plan-detail") ||
                          history.location.pathname.includes(
                            "learner/classes"
                          ) ||
                          history.location.pathname.includes(
                            "learner/about-us"
                          ) ||
                          history.location.pathname.includes("learner/blog") ||
                          history.location.pathname.includes(
                            "profile/my-achievement"
                          ) ||
                          history.location.pathname.includes(
                            "profile/class-history"
                          ) ||
                          history.location.pathname.includes("learner/course")
                            ? "dynamicScreen"
                            : ""
                        }
                        `}
                      >
                        <img
                          src={
                            GITHUB_ASSETS_HOST_URL +
                            `img/frame/Bottom_Frame.png`
                          }
                          alt="bottom-frame"
                          className="bottom_frame_img"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </>
        ) : (
          <>{children}</>
        )}
      </Suspense>
    </Fragment>
  )
}

export default React.memo(Layout)
// function jQuery(window: Window & typeof globalThis) {
//   throw new Error("Function not implemented.");
// }
