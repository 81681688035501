import "./customModal.scss"
import { GITHUB_ASSETS_HOST_URL } from "../../../helpers/api"
import useWindowSize from "../../dashboard/useWindowSize"
import { useEffect } from "react"
import $ from "jquery"
import React from "react"

const CustomModal = ({
  children,
  closeModal,
  title,
  PopupShown,
  styles,
}: any) => {
  useEffect(() => {
    if (PopupShown) {
      $("body").css("overflow", "hidden")
      $("html").css("overflow", "hidden")
      $("#root").css("overflow", "hidden")
      $("#root").css("height", "100vh")
    } else {
      $("body").css("overflow", "auto")
      $("html").css("overflow", "auto")
      $("#root").css("overflow", "auto")
      $("#root").css("height", "")
    }
    return () => {}
  }, [PopupShown])
  const { width, height }: any = useWindowSize()
  return (
    <>
      {PopupShown ? (
        <>
          <div className="custom-popup">
            <div
              className="popup_inner"
              style={{
                width: `${width < 768 ? width - 30 + "px" : "auto"}`,
                height: `${
                  width < 768 ? height - 200 + "px" : "auto"
                  // width < 768 ? (styles ? styles : height - 150 + "px") : "auto"
                }`,
                overflowY: "auto",
              }}
            >
              <h1 className="popup_Title">{title}</h1>

              <img
                src={GITHUB_ASSETS_HOST_URL + `img/popup-close.png`}
                alt="Close"
                onClick={closeModal}
                className="Popup_Close_Btn"
              />
              {children}
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default React.memo(CustomModal)
