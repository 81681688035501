import { ActionCreator, Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DashboardActionTypes } from './types';
import { ApplicationState } from '../index';
import api from '../../helpers/api';
// import { payloadData } from '../../productJSON'
// import { payloadProductData } from '../../payloadProduct'
// import { payloadFactoriesData } from '../../payloadFactory'

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const layoutsRequest: AppThunk = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: DashboardActionTypes.LAYOUT_REQUEST,
    });
    try {
      const payload = await api.get('/child/layout');
      return dispatch({
        type: DashboardActionTypes.LAYOUT_SUCCESS,
        payload: payload,
      });
    } catch (e) {
      return dispatch({
        type: DashboardActionTypes.LAYOUT_ERROR,
      });
    }
  };
};
