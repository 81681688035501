export const routeAnimationJson: any = {
  "/profile": {
    animation: "a-routeFadeIn",
  },
  "/calendar": {
    animation: "a-routeFadeOut",
  },
  "/dashboard/": {
    animation: "a-routeFadeOut",
  },
  "/dashboard": {
    animation: "a-routeFadeOut",
  },
  "dashboard": {
    animation: "a-routeFadeOut",
  },
  "/notification": {
    animation: "a-routeFadeout",
  },
  "/notebook": {
    animation: "a-routeFadeOut",
  },
  "/save-class": {
    animation: "a-routeFadeout",
  },
  "/explore-classes": {
    animation: "a-routeFadeout",
  },
  "/plan-detail": {
    animation: "a-routeFadeout",
  },
  "/topup-detail": {
    animation: "a-routeFadeout",
  },
  "/course": {
    animation: "a-routeFadeout",
  },
  "/classes": {
    animation: "a-routeFadeout",
  },
  "learner/course": {
    animation: "a-routeFadeout",
  },
  "/explore-classes/card": {
    animation: "a-routeFadeout",
  },
  "/thank-you": {
    animation: "a-routeFadeout",
  },
  "/failure": {
    animation: "a-routeFadeout",
  },
  "/attend-test": {
    animation: "a-routeFadeout",
  },
  "/preview-test-result": {
    animation: "a-routeFadeout",
  },
  "/compare-classes": {
    animation: "a-routeFadeout",
  },
  "/chat": {
    animation: "a-routeFadeout",
  },
  "/profile/account": {
    animation: "a-routeFadeOut",
  },
  "/profile/education-profile": {
    animation: "a-routeFadeIn",
  },
  "/profile/subscriptions": {
    animation: "a-routeFadeIn",
  },
  "/profile/avatar": {
    animation: "a-routeFadeIn",
  },
  "/profile/space-ship": {
    animation: "a-routeFadeIn",
  },
  "/profile/meekoins": {
    animation: "a-routeFadeIn",
  },
  "/profile/meekoins-journey": {
    animation: "a-routeFadeIn",
  },
  "/profile/meekoins-games" : {
    animation: "a-routeFadeIn",
  },
  "/Profile/MeekoinsGalaxy": {
    animation: "a-routeFadeIn",
  },
  "/profile/class-history": {
    animation: "a-routeFadeIn",
  },
  "/learner/policies/privacy-policy/": {
    animation: "a-routeFadeIn",
  },
  "/learner/policies/privacy-policy-teacher-user/": {
    animation: "a-routeFadeIn",
  },
  "/learner/policies/terms-and-conditions-meeko-teacher/": {
    animation: "a-routeFadeIn",
  },
  "/learner/policies/terms-and-conditions-meeko-users/": {
    animation: "a-routeFadeIn",
  },
  "learner/about-us": {
    animation: "a-routeFadeIn",
  },
}

export const dynamicARoute: any = ["ExploreClasses/Card/"]
