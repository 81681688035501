import React, { useEffect, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import "./ResHeader.scss"
import { SidebarData, SubSilderData } from "./SideBarData"
import Slider from "react-slick"
import { useHistory } from "react-router-dom"
import Countdown from "react-countdown"
import api, { GITHUB_ASSETS_HOST_URL } from "../../../helpers/api"
import moment from "moment"
import Popup from "../Comman_Popup/Popup"
import { logoutUser } from "../../../store/auth/action"
import { ThunkDispatch } from "redux-thunk"
import { AnyAction } from "redux"
import { connect } from "react-redux"
import { getNotification } from "../../../helpers/socket"
import { ApplicationState } from "../../../store"

interface PropsFromState {
  userType: any
  errors?: any
  elearnerNotificationCounter: any
}
interface PropsFromDispatch {
  logoutUser: () => any
}

type AllProps = PropsFromState & PropsFromDispatch

const ResHeader: React.FC<AllProps> = ({
  logoutUser,
  userType = "",
  elearnerNotificationCounter,
}: any) => {
  const [sidebar, setSidebar] = useState(false)
  const [childClassDetails, setChildClassDetails]: any = useState({})

  const showSidebar = () => setSidebar(!sidebar)

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          infinite: false,
          autoplay: false,
          speed: 200,
          // centerMode: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          // infinite: true,
          autoplay: false,
          speed: 200,
          // centerMode: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
      // {
      //   breakpoint: 380,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     dots: false,
      //     // infinite: true,
      //     autoplay: false,
      //     speed: 200,
      //     centerMode: true,
      //     prevArrow: false,
      //     nextArrow: false,
      //   },
      // },
    ],
  }
  useEffect(() => {
    if (localStorage.getItem("role")) {
      getNotification(-1)
    }
    if (userType != "guestUser") {
    }
  }, [])

  useEffect(() => {}, [elearnerNotificationCounter])

  const history: any = useHistory()
  const HeaderClasses = ["/profile", "dashboard", "/calendar"]
  const [showPopup, setShowPopup] = useState(false)

  const [colorChange] = useState(false)
  const ShowPopUpHandler = (index) => {
    setShowPopup(index)
  }
  const CountdownRenderer = ({ total, days, hours, minutes, seconds }: any) => {
    console.log(total, "===total")
    return (
      <>
        {total ? (
          <div className={`${total >= 300000 ? "disabled option" : "option"}`}>
            <div
              className={`${
                total >= 300000
                  ? "disabled live-class blick_disable"
                  : "live-class"
              }`}
            >
              <NavLink
                to={`/live-class/${childClassDetails?.classId}/${childClassDetails?.sessionId}`}
                activeClassName="active_nav"
              >
                <i className="fa fa-video-camera"></i>
                <span> Join Live Class</span>
              </NavLink>
            </div>
            {/* <div className="live-class-timer">
              <div className="TimeData">
                <span> {days}05</span>
                <span>{hours}02</span>
                <span>{minutes}06</span>
                <span>{seconds}01</span>
              </div>
            </div> */}
          </div>
        ) : (
          <li
            className={`${
              Object.keys(childClassDetails).length === 0
                ? "disabled option"
                : "option"
            }`}
          >
            <NavLink
              to={`/live-class/${childClassDetails?.classId}/${childClassDetails?.sessionId}`}
              activeClassName="active_nav"
            >
              <i className="fa fa-video-camera"></i>
              <span> Join Live Class</span>
            </NavLink>
          </li>
        )}
      </>
    )
  }

  useEffect(() => {
    console.log("here in useEffect")
    callClassCourseApi()
  }, [])

  const callClassCourseApi = async () => {
    try {
      const payload = await api.get("/child/liveclassinfo/")
      if (payload.status === 200) {
        console.log(payload.data, "===payload.data")
        setChildClassDetails(payload.data)
      }
    } catch (e) {
      console.log("e", e)
    }
  }
  const logoutHandler = () => {
    return setShowPopup(!showPopup)
  }
  
  return (
    <>
      {HeaderClasses.includes(history.location.pathname) ||
      history.location.pathname.includes("dashboard") ||
      history.location.pathname.includes("educator/profile") ||
      history.location.pathname.includes("ExploreClasses/view-all-subject") ||
      history.location.pathname.includes("profile/account") ||
      history.location.pathname.includes("profile/subscriptions") ||
      history.location.pathname.includes("profile/avatar") ||
      history.location.pathname.includes("profile/space-ship") ||
      history.location.pathname.includes("Profile/Flexems") ||
      history.location.pathname.includes("profile/education-profile") ||
      history.location.pathname.includes("profile/meekoins") ||
      history.location.pathname.includes("profile/meekoins-journey") ||
      history.location.pathname.includes("Profile/MeekoinsGalaxy") ||
      history.location.pathname.includes("Profile/MeekoinsGalaxy") ||
      history.location.pathname.includes("profile/my-achievement") ||
      history.location.pathname.includes("ExploreClasses") ||
      history.location.pathname.includes("profile/class-history") ? (
        <>
          <div className="Responsive_Header_Container">
            <div onClick={showSidebar}>
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Responsive/menu.png`}
                alt="menu"
                className="_Responsive_Img-"
              />
            </div>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
              <ul className="nav-menu-items" onClick={showSidebar}>
                <li className="navbar-toggle">
                  <i className="fa fa fa-times"></i>
                </li>
                {SidebarData.map((item, index) => {
                  if (item.title !== "How it's Work") {
                    return (
                      <li key={index} className={item.cName}>
                        <Link to={item.path} className="Sidebar_Data">
                          <div className="Slider_Icon_Header">{item.icon}</div>

                          <span className="Title_Sidebar">{item.title}</span>
                        </Link>
                      </li>
                    )
                  }
                })}

                <div className="top_outer_arrow" onClick={logoutHandler}>
                  <div className="img_bg">
                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/logout_icon.png`}
                      alt="arrow-top"
                    />
                  </div>
                  <span>Logout</span>
                </div>

                <Popup
                  PopupShown={showPopup}
                  ActionToParent={ShowPopUpHandler}
                  eventHandler={logoutUser}
                  Message={"Are you sure you want to logout?"}
                />
              </ul>
            </nav>
            <div>
              <Link to="/dashboard">
                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/logo.png`}
                  alt="logo"
                  className="Logo_Responsive"
                />
              </Link>
            </div>
            <div
              className="notification_bar"
              onClick={() => {
                history.push("/notification/")
              }}
            >
              {elearnerNotificationCounter &&
              elearnerNotificationCounter > 0 ? (
                <div className="notification-count">
                  {elearnerNotificationCounter &&
                  elearnerNotificationCounter > 99
                    ? `99+`
                    : elearnerNotificationCounter}
                </div>
              ) : null}
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Responsive/Notification.png`}
                alt="Notification"
              />
            </div>
          </div>

          <div className="Slider_Responsive res-header">
            <Slider accessibility={true} {...settings}>
              {SubSilderData.map((item, index) => {
                if (item.title == "Join Live Class") {
                  return (
                    <div
                      className="live-class-option"
                      style={{ background: item.color }}
                    >
                      {console.log(
                        childClassDetails?.date,
                        "===childClassDetails?.date"
                      )}
                      <Countdown
                        date={new Date(childClassDetails?.date)}
                        renderer={(data) => CountdownRenderer(data)}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className="SubSliderContainer">
                      <Link
                        to={item.path}
                        className="Sub_Link"
                        style={{ background: item.color }}
                      >
                        <div className="Slider_Icon_Header Explore_Classes_icon">
                          {item.icon}
                        </div>

                        <span>{item.title}</span>
                      </Link>
                    </div>
                  )
                }
              })}
            </Slider>
          </div>
        </>
      ) : (
        <>
          <div className="Responsive_Header_Container">
            <div onClick={showSidebar}>
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Responsive/menu.png`}
                alt="menu"
                className="_Responsive_Img-"
              />
            </div>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
              <ul className="nav-menu-items" onClick={showSidebar}>
                <li className="navbar-toggle">
                  <i className="fa fa fa-times"></i>
                </li>
                {SidebarData.map((item, index) => {
                  if (item.title !== "How it's Work") {
                    return (
                      <li key={index} className={item.cName}>
                        <Link to={item.path} className="Sidebar_Data">
                          <div className="Slider_Icon_Header">{item.icon}</div>

                          <span className="Title_Sidebar">{item.title}</span>
                        </Link>
                      </li>
                    )
                  }
                })}

                <div className="top_outer_arrow" onClick={logoutHandler}>
                  <div className="img_bg">
                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/logout_icon.png`}
                      alt="arrow-top"
                    />
                  </div>
                  <span>Logout</span>
                </div>

                <Popup
                  PopupShown={showPopup}
                  ActionToParent={ShowPopUpHandler}
                  eventHandler={logoutUser}
                  Message={"Are you sure you want to logout?"}
                />
              </ul>
            </nav>
            <div>
              <Link to="/dashboard">
                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/logo.png`}
                  alt="logo"
                  className="Logo_Responsive"
                />
              </Link>
            </div>
            <div
              className="notification_bar"
              onClick={() => {
                history.push("/notification/")
              }}
            >
              {elearnerNotificationCounter &&
              elearnerNotificationCounter > 0 ? (
                <div className="notification-count">
                  {elearnerNotificationCounter &&
                  elearnerNotificationCounter > 99
                    ? `99+`
                    : elearnerNotificationCounter}
                </div>
              ) : null}
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Responsive/Notification.png`}
                alt="Notification"
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
const mapStateToProps = ({ auth, notification }: ApplicationState) => ({
  userData: auth.userData,
  loading: notification.loading,
  elearnerNotificationCounter: notification.elearnerNotificationCounter,
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    logoutUser: async () => {
      await dispatch(logoutUser())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResHeader)
