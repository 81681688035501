import React from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ApplicationState } from "../../store";
import api from "../../helpers/api";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { layoutsRequest } from "../../store/dashboard/action";
import { startVideoRequest } from "../../store/dashboardvideo/action";

interface PropsFromState {
  video: any;
}
interface PropsFromDispatch {
  layoutsRequest: () => any;
  startVideoRequest: (data) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

const DashboardVideoPlayer: React.FC<AllProps> = ({
  video,
  layoutsRequest,
  startVideoRequest,
}: any) => {
  const endOfVideo = async () => {
    startVideoRequest("");
    layoutsRequest();
  };

  return (
    <>
      {video ? (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            zIndex: 9999999,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactPlayer
            className="react-player"
            url={`${video}`}
            playing={true}
            controls={false}
            width="100%"
            height="100%"
            onEnded={() => endOfVideo()}
          />
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ video }: ApplicationState) => ({
  video: video,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    layoutsRequest: () => {
      dispatch(layoutsRequest());
    },
    startVideoRequest: (data) => {
      dispatch(startVideoRequest(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardVideoPlayer);
