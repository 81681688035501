import React from "react";
import { GITHUB_ASSETS_HOST_URL } from "../../../helpers/api";

export const SidebarData = [
  {
    title: "Space Station",
    path: "/dashboard/",
    icon: <img src={GITHUB_ASSETS_HOST_URL + `img/icn.png`} alt="icon" />,
    cName: "nav-text",
  },

  {
    title: "Chat Box",
    path: "/chat",
    icon: <img src={GITHUB_ASSETS_HOST_URL + `img/icnn11.png`} alt="icon" />,
    cName: "nav-text",
  },

  {
    title: "Note Book",
    path: "/notebook",
    icon: <img src={GITHUB_ASSETS_HOST_URL + `img/icn2.png`} alt="meeko-2" />,
    cName: "nav-text",
  },
  {
    title: "Saved Classes",
    path: "/save-class",
    icon: <img src={GITHUB_ASSETS_HOST_URL + `img/icn3.png`} alt="meeko-2" />,
    cName: "nav-text",
  },

  {
    title: "Notification",
    path: "/notification",
    icon: (
      <img src={GITHUB_ASSETS_HOST_URL + `img/icn4.png`} alt="meeko-icon-4" />
    ),
    cName: "nav-text",
  },

  {
    title: "How it's Work",
    path: "/dashboard/",
    icon: (
      <img
        onClick={() => localStorage.setItem("websiteTour", "true")}
        src={GITHUB_ASSETS_HOST_URL + `img/icn5.png`}
        alt="meeko-icon-5"
      />
    ),
    cName: "nav-text",
  },

  {
    title: "Refer",
    path: "/refer",
    icon: (
      <img src={GITHUB_ASSETS_HOST_URL + `img/icn6.png`} alt="meeko-icon-4" />
    ),
    cName: "nav-text",
  },

  {
    title: "Help Desk",
    path: "/learner/help-desk",
    icon: (
      <img src={GITHUB_ASSETS_HOST_URL + `img/hyelptaskicn.png`} alt="help" />
    ),
    cName: "nav-text",
  },
];

export const SubSilderData = [
  {
    title: "Join Live Class",
    path: "/live-class",
    icon: <i className="fa fa-video-camera"></i>,
    color: "#efc623",
  },
  {
    title: "Explore Classes",
    path: "/explore-classes",
    icon: <img src={GITHUB_ASSETS_HOST_URL + `img/explore_class.png`} />,
    color: "#ac8df5",
  },
  {
    title: "Calendar",
    path: "/calendar",
    icon: <i className="fa fa-calendar"></i>,
    color: "#f0bc76",
  },
  {
    title: "Profile",
    path: "/profile",
    icon: <i className="fa fa-user"></i>,
    color: "#84ed7c",
  },
  // {
  //   title: "Pre Recorded Classes",
  //   path: "/explore-pre-recorded-classes",
  //   icon: (
  //     <img
  //       src={
  //         GITHUB_ASSETS_HOST_URL + `img/explore_class.png`
  //       }
  //     />
  //   ),
  //   color: "#ac8df5",
  // },
];
