import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { logoutUser } from "../../../store/auth/action";
import { useHistory } from "react-router-dom";
import "./header.scss";
import Countdown from "react-countdown";
import api, { GITHUB_ASSETS_HOST_URL } from "../../../helpers/api";
import moment from "moment";
import Popup from "../Comman_Popup/Popup";
import { getNotification } from "../../../helpers/socket";
import { ApplicationState } from "../../../store";
import CustomModal from "../../common/customModal/CustomModal";

interface PropsFromState {
  userType: any;
  errors?: any;
  logoutUser: any;
  userData: any;
  loading: any;
  elearnerNotificationCounter: any;
}
interface PropsFromDispatch {
  logoutUser: () => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

const Header: React.FC<AllProps> = ({
  logoutUser,
  userType = "",
  userData,
  loading,
  elearnerNotificationCounter,
}: any) => {
  const [childClassDetails, setChildClassDetails]: any = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const [liveClassPopup, setLiveClassPopup]: any = useState(false);
  const [isClosed, setIsClosed]: any = useState(false);
  const [userDetail, setUserDetail]: any = useState(
    JSON.parse(localStorage.getItem("userDetail") || "")
  );
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    getNotification(-1);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {}, [elearnerNotificationCounter]);

  const changeClass = [
    "/explore-classes",
    "/course",
    "/explore-classes/card",
    "/learner/classes",
    "/attend-test",
    "/preview-test-result",
    "/compare-classes",
    "/thank-you",
    "/failure",
    "/plan-detail",
    "/topup-detail",
  ];

  const [colorChange] = useState(false);

  const ShowPopUpHandler = (index) => {
    setShowPopup(index);
  };

  useEffect(() => {
    console.log("here in useEffect");
    callClassCourseApi();
  }, []);

  const callClassCourseApi = async () => {
    try {
      const payload = await api.get("/child/liveclassinfo/");
      if (payload.status === 200 && Object.keys(payload.data).length) {
        console.log(payload.data, "=====payload.data");
        setChildClassDetails(payload.data);
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const logoutHandler = () => {
    return setShowPopup(!showPopup);
  };

  const CountdownRenderer = ({ total, days, hours, minutes, seconds }: any) => {
    console.log(total, "=====total");
    if (total < 300000 && total !== 0 && !isClosed) {
      setLiveClassPopup(true);
    }
    return (
      <>
        {total ? (
          <>
            <li className={`${total >= 300000 ? "disabled" : ""}`}>
              <NavLink
                to={`/live-class/${childClassDetails?.classId}/${childClassDetails?.sessionId}`}
                activeClassName="active_nav"
                data-tut="reactour__joinliveclass"
              >
                Join Live Class
              </NavLink>
            </li>
            <li className="live-class-timer">
              <div className="TimeData">
                <span> {days}</span>
                <span>{hours}</span>
                <span>{minutes}</span>
                <span>{seconds}</span>
              </div>
            </li>
          </>
        ) : (
          <>
            <li
              className={`${
                Object.keys(childClassDetails).length === 0 ? "disabled" : ""
              }`}
            >
              <NavLink
                to={`/live-class/${childClassDetails?.classId}/${childClassDetails?.sessionId}`}
                activeClassName="active_nav"
                data-tut="reactour__joinliveclass"
              >
                Join Live Class
              </NavLink>
            </li>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={`main_header_section ${
          changeClass.includes(history.location.pathname) ||
          history.location.pathname.includes("/explore-classes/card/") ||
          history.location.pathname.includes("/learner/classes/") ||
          history.location.pathname.includes("/course/") ||
          history.location.pathname.includes("explore-classes/search-text") ||
          history.location.pathname.includes("educator/profile") ||
          history.location.pathname.includes("/attend-test/") ||
          history.location.pathname.includes("/preview-test-result/") ||
          history.location.pathname.includes("/thank-you") ||
          history.location.pathname.includes("/failure") ||
          history.location.pathname.includes("/learner/policies") ||
          history.location.pathname.includes("learner/about-us")
            ? "header_Relative"
            : ""
        } ${scrollPosition > 50 ? "main_header_section_scroll" : ""}`}
        style={{ backgroundColor: colorChange ? "red" : "" }}
      >
        <div
          className={`main_header_row ${
            changeClass.includes(history.location.pathname) ||
            history.location.pathname.includes("/explore-classes/card") ||
            history.location.pathname.includes("/course/") ||
            history.location.pathname.includes("explore-classes/search-text") ||
            history.location.pathname.includes("educator/profile") ||
            history.location.pathname.includes("/attend-test") ||
            history.location.pathname.includes("/preview-test-result") ||
            history.location.pathname.includes("/thank-you") ||
            history.location.pathname.includes("/failure") ||
            history.location.pathname.includes("/plan-detail") ||
            history.location.pathname.includes("/topup-detail")
              ? "header_Absolute"
              : ""
          }`}
        >
          <div className="main_header_logo_col">
            <Link to="/dashboard">
              <img src={GITHUB_ASSETS_HOST_URL + `img/logo.png`} alt="logo" />
            </Link>
          </div>
          <div className="main_header_menu_col">
            <ul className="header_ul">
              <li>
                <NavLink
                  to="/profile"
                  activeClassName="active_nav"
                  data-tut="reactour__myprofile"
                >
                  My Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/calendar"
                  activeClassName="active_nav"
                  data-tut="reactour__calendar"
                >
                  Calendar
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard" activeClassName="active_nav">
                  Space Station
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/explore-classes"
                  activeClassName="active_nav"
                  data-tut="reactour__exploreclass"
                >
                  Explore Classes
                </NavLink>
              </li>
              <Countdown
                date={new Date(childClassDetails?.date)}
                renderer={(data) => CountdownRenderer(data)}
              />
            </ul>
          </div>
          <div className="main_header_right_col">
            <div
              className="top_outer_arrow notification_bar"
              onClick={() => {
                history.push("/notification");
              }}
            >
              {elearnerNotificationCounter &&
              elearnerNotificationCounter > 0 ? (
                <div className="notification-count">
                  {elearnerNotificationCounter &&
                  elearnerNotificationCounter > 99
                    ? `99+`
                    : elearnerNotificationCounter}
                </div>
              ) : null}
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/header_notification.png`}
                alt="arrow-top"
              />
            </div>

            <div className="top_outer_arrow" onClick={logoutHandler}>
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/logout_icon.png`}
                alt="arrow-top"
              />
            </div>

            <Popup
              PopupShown={showPopup}
              ActionToParent={ShowPopUpHandler}
              eventHandler={logoutUser}
              Message={"Are you sure you want to logout?"}
            />
          </div>
        </div>
      </div>
      <CustomModal
        closeModal={() => {
          setLiveClassPopup(false);
          setIsClosed(true);
        }}
        PopupShown={liveClassPopup}
        title={``}
      >
        <div className="live-class-end-popup">
          <div className="live-class-end-section">
            <p>
              Hi{" "}
              {userDetail && userDetail?.fullName && userDetail?.fullName !== ""
                ? userDetail?.fullName
                : userDetail?.userName}{" "}
              Your next class {childClassDetails?.title} starts in{" "}
              {moment(childClassDetails?.date).local().format("hh:mm A")}
            </p>
          </div>
          <div className="live-class-end-button">
            <button
              className="popup-button okay-button"
              type="submit"
              value=""
              onClick={() => {
                history.push(
                  `/live-class/${childClassDetails?.classId}/${childClassDetails?.sessionId}`
                );
              }}
            >
              <span>Go Live</span>
            </button>
            <button
              className="popup-button cancel-button"
              type="submit"
              value=""
              onClick={() => {
                setLiveClassPopup(false);
                setIsClosed(true);
              }}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
const mapStateToProps = ({ auth, notification }: ApplicationState) => ({
  userData: auth.userData,
  loading: notification.loading,
  elearnerNotificationCounter: notification.elearnerNotificationCounter,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    logoutUser: async () => {
      await dispatch(logoutUser());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
