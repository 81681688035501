import React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { connect } from "react-redux"
// import Header from '../components/layout/header/header';
// import LiveClassHeader from '../components/layout/liveClassHeader/liveClassHeader';
import "../index.scss"
import Layout from "./Layout"

interface MyRouteProps extends RouteProps {
  component: any
  authenticated: boolean
  liveClasslayout?: boolean
  rest?: any
  componentProps?: object
  withLayout?: boolean
}

const PrivateRoute: React.FC<MyRouteProps> = ({
  component: Component,
  authenticated,
  role,
  withLayout = true,
  liveClasslayout,
  componentProps,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated && role === "ELearner" ? (
          <>
            {withLayout ? (
              <Layout {...props}>
                <Component
                  {...props}
                  {...componentProps}
                  liveClasslayout={liveClasslayout}
                />
              </Layout>
            ) : (
              <Component
                {...props}
                {...componentProps}
                liveClasslayout={liveClasslayout}
              />
            )}
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
  role: state.auth.role,
})

export default connect(mapStateToProps)(PrivateRoute)
